<template>
  <div class="tw-flex tw-items-center tw-justify-center tw-align-center tw-h-screen px-2">
    <b-card-code>
      <b-card-body>
        <b-row>
          <b-col md="12">
            <h4 class="mb-1 tw-text-center tw-flex tw-items-center tw-flex-col">
              <feather-icon icon="FileTextIcon" size="55" class="tw-text-center mb-2" />
              Verificar documento assinado
            </h4>

            <b-form-group label-for="codigoResponsavel">
              <validation-provider name="Código" rules="required">
                <label class="tw-text-gray-600 tw-text-center" for="codigoResponsavel">Insira o código de verificação</label>
                <b-form-input class="mt-1" id="codigoResponsavel" placeholder="Código de Verificação" v-model="codigo" />
              </validation-provider>
            </b-form-group>
          </b-col>

          <transition name="fade" mode="out-in">
            <b-col key="1" v-if="assinar == 'pendente'" md="12" class="tw-flex">
              <b-button
                class="tw-flex-grow"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                :disabled="codigo.length < 6"
                @click="validarDocumento"
              >
                Validar Documento
              </b-button>
            </b-col>
          </transition>
        </b-row>
      </b-card-body>
    </b-card-code>
  </div>
</template>

<style>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  @tailwind base;
  @tailwind components;
  @tailwind utilities;
</style>

<script>
  import useJwt from '@/auth/jwt/useJwt'
  import Ripple from 'vue-ripple-directive'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { pipe } from 'fp-ts/function'
  import Cleave from 'vue-cleave-component'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

  export default {
    directives: {
      Ripple,
    },
    props: {
      token: {
        type: String,
        default: null,
      },
    },
    components: {
      BCardCode,
      Cleave,
      ValidationProvider,
      ValidationObserver,
    },
    data() {
      return {
        codigo: '',
        assinar: 'pendente',
      }
    },
    methods: {
      async validarDocumento() {
        await useJwt
          .post('assinatura-documentos/GetDocumentoAssinado', {
            codigo: this.codigo,
          })
          .then((response) => {
            this.$message.success('Documento encontrado, iniciando download...')
            window.open(response.data.link)
            this.codigo = ''
          })
          .catch((error) => {
            console.error(error)
            this.$message.error('Erro ao validar documento')
          })
      },
      toast: function () {
        return {
          success: async (message) => {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                },
              },
              {
                position: 'top-right',
                timeout: 3000,
              },
            )
          },
          error: async (message) => {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'BellIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'top-right',
                timeout: 3000,
              },
            )
          },
          info: async (message) => {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'BellIcon',
                  variant: 'info',
                },
              },
              {
                position: 'top-right',
                timeout: 3000,
              },
            )
          },
        }
      },
    },
  }
</script>
